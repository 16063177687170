import { isArray, isObject, isNil, isString } from 'lodash'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import mathjs from 'mathjs'
import numeral from 'numeral'

import e_FunctionParameterType from '@appfarm/common/enums/e_FunctionParameterType'
import e_Cardinality from '@appfarm/common/enums/e_Cardinality'
import e_FunctionLibrary from '@appfarm/common/enums/e_FunctionLibrary'
import e_ActionNodeSelectionType from '@appfarm/common/enums/e_ActionNodeSelectionType'

import { UnexpectedDataTypeError } from '#utils/clientErrors'
import { getActionParamFromContextData, getIteratorParamFromContextData } from '#utils/contextDataUtils'
import logger from '#logger/logger'

const defaultLogger = logger

const getSingleValueFromDataBinding = (
	appController,
	contextData,
	dataBinding,
	selectionType,
	useDisplayValue,
	logger = defaultLogger
) => {
	let data = appController.getDataFromDataBinding({ contextData, dataBinding, logger })
	if (!data) return undefined

	if (isArray(data) && data.length === 1) data = data[0]

	let nodeName = dataBinding.nodeName || '_id'
	if (dataBinding.edgeDataBinding) nodeName = dataBinding.edgeDataBinding.nodeName || '_id'

	if (selectionType && selectionType !== e_ActionNodeSelectionType.ALL && !dataBinding.edgeDataBinding) {
		const referenceDataSourceId = appController.getDataSourceIdFromDataBindingProperty(dataBinding)

		switch (selectionType) {
			case e_ActionNodeSelectionType.CONTEXT: {
				const contextObjects = contextData[referenceDataSourceId]
				if (!contextObjects || !contextObjects.length) return undefined // no context --> no data

				const dataValue = data[nodeName]
				if (!dataValue || !dataValue.length) return undefined

				if (dataValue.includes(contextObjects[0]._id)) return contextObjects[0]._id
				else return undefined
			}
			case e_ActionNodeSelectionType.SELECTED: {
				const referenceDataSource = appController.getDataSource(referenceDataSourceId)
				const selectedObjects = referenceDataSource.getSelectedObjects()
				if (!selectedObjects || !selectedObjects.length) return undefined

				const dataValue = data[nodeName]
				if (!dataValue || !dataValue.length) return undefined

				const selectedObjectsDict = selectedObjects.reduce((dict, item) => {
					dict[item._id] = true
					return dict
				}, {})

				return dataValue.filter((objectId) => selectedObjectsDict[objectId])
			}
		}
	}

	if (useDisplayValue) {
		const enumeratedTypeId = dataBinding.edgeDataBinding
			? dataBinding.edgeDataBinding.enumeratedTypeId
			: dataBinding.enumeratedTypeId

		if (enumeratedTypeId && !isNil(data[nodeName])) {
			const enumeratedType = appController.getEnumeratedTypeValue({
				enumeratedTypeId,
				enumeratedTypeValue: data[nodeName],
			})

			return enumeratedType?.name
		}
	}

	return data[nodeName]
}

const getLibrary = (libraryIdentificator, { timeZone } = {}, logger = defaultLogger) => {
	switch (libraryIdentificator) {
		case e_FunctionLibrary.MOMENT: {
			if (timeZone) {
				const momentInstance = function () {
					return moment(...arguments).tz(timeZone)
				}

				momentInstance.duration = moment.duration
				return momentInstance
			} else {
				return moment
			}
		}

		case e_FunctionLibrary.MOMENT_TIMEZONE:
			logger.debug('DEPRECATION WARNING: Moment Timezone -> Moment is now aware of client timezone')
			return momentTimezone

		case e_FunctionLibrary.MATH:
			return mathjs

		case e_FunctionLibrary.NUMERAL:
			return numeral
		default:
			throw new Error('Library not implemented')
	}
}

const evaluateFunctionValue = ({
	appController,
	contextData,
	functionValue,
	selfObject,
	isTranslated,
	ignoreReturnDatatypeCheck,
	reThrowError,
	logger = defaultLogger,
}) => {
	// Generate arguments
	const paramsAndArgs =
		functionValue.functionParameters?.map((functionParameter) => {
			switch (functionParameter.functionParameterType) {
				case e_FunctionParameterType.DATA_SOURCE:
					return (() => {
						const dataSource = appController.getDataSource(functionParameter.dataSourceId)
						let argument = dataSource.getObjectsBySelectionType({
							selectionType: functionParameter.selectionType,
							staticFilter: functionParameter.staticFilter,
							filterDescriptor: functionParameter.filterDescriptor,
							contextData: contextData,
						})

						if (functionParameter.parameterCardinality === e_Cardinality.ONE) {
							if (argument && argument.length) {
								argument = argument[0]
							} else {
								argument = null
							}
						}

						return {
							parameter: functionParameter.name,
							argument: argument,
						}
					})()

				case e_FunctionParameterType.DATA_BINDING:
					return {
						parameter: functionParameter.name,
						argument: getSingleValueFromDataBinding(
							appController,
							contextData,
							functionParameter.dataBinding,
							functionParameter.selectionType,
							functionParameter.useDisplayValue,
							logger
						),
					}

				case e_FunctionParameterType.ENUMERATED_TYPE_VALUE: {
					if (isTranslated) {
						// refresh value
						const enumTypeValue = appController.getEnumeratedTypeValue({
							enumeratedTypeId: functionParameter.enumeratedTypeId,
							enumeratedTypeValue: functionParameter.enumeratedTypeParameterValue.value,
						})
						return {
							parameter: functionParameter.name,
							argument: enumTypeValue,
						}
					}
					return {
						parameter: functionParameter.name,
						argument: functionParameter.enumeratedTypeParameterValue,
					}
				}

				case e_FunctionParameterType.ENUMERATED_TYPE_NAME_DICT:
					return {
						parameter: functionParameter.name,
						argument: appController.getEnumeratedTypeNameDict(functionParameter.enumeratedTypeId),
					}

				case e_FunctionParameterType.ACTION_PARAM:
					return {
						parameter: functionParameter.name,
						argument: getActionParamFromContextData(contextData, functionParameter.paramId),
					}
				case e_FunctionParameterType.ITERATOR_PARAM:
					return {
						parameter: functionParameter.name,
						argument: getIteratorParamFromContextData(contextData, functionParameter.paramId),
					}

				case e_FunctionParameterType.LIBRARY:
					return {
						parameter: functionParameter.name,
						argument: getLibrary(
							functionParameter.libraryIdentificator,
							{
								timeZone: appController.getAppTimeZone(),
							},
							logger
						),
					}

				case e_FunctionParameterType.SELF:
					return {
						parameter: functionParameter.name,
						argument: selfObject[functionParameter.nodeName],
					}

				case e_FunctionParameterType.CONSTANT:
					return {
						parameter: functionParameter.name,
						argument: functionParameter.value,
					}

				default:
					throw new Error('Unknown functionParameterType')
			}
		}) || []

	const argumentObject = paramsAndArgs.reduce(
		(argumentObject, paramAndArg) => {
			argumentObject[paramAndArg.parameter] = paramAndArg.argument
			return argumentObject
		},
		{ AF_LOGGER: logger }
	)

	// Using server side generated functions
	const functionNamespace = 'AF_APP_' + appController.getActiveAppId()
	const functionName = 'fn' + functionValue.id
	const functionForExec = window[functionNamespace][functionName]

	let result
	try {
		result = functionForExec(argumentObject)
	} catch (err) {
		if (reThrowError) throw err
		if (functionForExec === undefined) {
			logger.error('Unable to find function in window global variable!')
		}

		logger.error(`Error running function: ${err.message}`, {
			payload: {
				message: `Error running function: ${err.message}`,
				functionName: functionName,
				functionParameters: functionValue.functionParameters,
				functionId: functionValue.id,
				arguments: argumentObject,
				error: err,
			},
		})
		return undefined
	}

	// check if valid array in case of a multi reference, should be array of Ids (string)
	const isInvalidArray = isArray(result) && result.some((value) => !isString(value))

	if (!ignoreReturnDatatypeCheck && ((isObject(result) && !isArray(result)) || isInvalidArray)) {
		if (result instanceof moment) {
			logger.error('Moment was returned in function. Expected primitive type', {
				payload: {
					message: 'Moment was returned in function. Expected primitive type',
					functionName: functionName,
					functionParameters: functionValue.functionParameters,
					functionId: functionValue.id,
					result: result,
					fix: 'How to fix: Moment type object was returned - make sure to apply .toJSON() or the intended formatting function before return',
				},
			})
			if (reThrowError) {
				throw new UnexpectedDataTypeError('Moment was returned in function. Expected primitive type', {
					functionId: functionValue.id,
					functionName: functionName,
					result: result,
					fix: 'How to fix: Moment type object was returned - make sure to apply .toJSON() or the intended formatting function before return',
				})
			}
		} else if (isInvalidArray) {
			logger.error(
				'Invalid array was returned in a function value. Expected array of strings or primitive type',
				{
					payload: {
						message:
							'Invalid array was returned in a function value. Expected array of strings or primitive type',
						functionName: functionName,
						functionParameters: functionValue.functionParameters,
						functionId: functionValue.id,
						result: result,
						fix: 'How to fix: Make sure to return the correct datatype in the function with this error',
					},
				}
			)
			if (reThrowError) {
				throw new UnexpectedDataTypeError('Invalid array was returned in a function value', {
					functionId: functionValue.id,
					functionName: functionName,
					result: result,
					fix: 'How to fix: Make sure to return the correct datatype in the function with this error',
				})
			}
		} else {
			logger.error('Object was returned in a function value. Expected primitive type', {
				payload: {
					message: 'Object was returned in a function value. Expected primitive type',
					functionName: functionName,
					functionParameters: functionValue.functionParameters,
					functionId: functionValue.id,
					result: result,
					fix: 'How to fix: Make sure to return the correct datatype in the function with this error',
				},
			})
			if (reThrowError) {
				throw new UnexpectedDataTypeError('Object was returned in a function value', {
					functionId: functionValue.id,
					functionName: functionName,
					result: result,
					fix: 'How to fix: Make sure to return the correct datatype in the function with this error',
				})
			}
		}
		return undefined
	}

	return result
}

export default evaluateFunctionValue
