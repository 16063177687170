/******************************************************************************
 *
 * Loader for AppList
 *
 *****************************************************************************/
import axios from 'axios'
import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setAppList } from '#actions/metadataActions'
import e_ScreenSize from '@appfarm/common/enums/e_ScreenSize'

const getScreenSize = () => {
	const width = window.innerWidth
	if (width <= 599) return e_ScreenSize.EXTRA_SMALL
	if (width <= 1239) return e_ScreenSize.SMALL
	if (width <= 1439) return e_ScreenSize.MEDIUM
	return e_ScreenSize.LARGE
}

const AppListLoader = () => {
	const [blocker, setBlocker] = useState(false)
	const isLoggedIn = useSelector((state) => state.authState.isLoggedIn)
	const wantedChecksum = useSelector((state) => state.metaData.latestGlobalChecksums.appList)
	const [loadedChecksum, setLoadedChecksum] = useState(null)
	const requestPending = useRef(false)

	const dispatch = useDispatch()

	useEffect(() => {
		if (requestPending.current) return
		if (blocker) return
		if (!wantedChecksum) return
		if (wantedChecksum === loadedChecksum) return
		if (!isLoggedIn) return // Both anonymous and authenticated

		const controller = new AbortController()
		requestPending.current = true

		axios
			.get(`/api/v1/apps?v=${wantedChecksum}`, {
				signal: controller.signal,
			})
			.then((result) => {
				let appList = result.data
				const screenSize = getScreenSize()
				appList = appList.map((item) => {
					if (item?.screenSizeAvailability?.[screenSize] || item?.error?.type === 'NoAccess') {
						return item
					} else {
						return {
							id: item.id,
							readableId: item.readableId,
							error: {
								type: 'UnsupportedDevice',
							},
						}
					}
				})

				dispatch(setAppList(appList))
				setLoadedChecksum(wantedChecksum)
			})
			.catch((err) => {
				if (axios.isCancel(err)) return
				console.error('Failed to get App-list', err)

				// Debounce - dont retry before x seconds
				// TODO: Construct a generic debouncer
				setBlocker(true)
				setTimeout(() => setBlocker(false), 5000)
			})
			.finally(() => {
				requestPending.current = false
			})

		return () => controller.abort()
	}, [blocker, isLoggedIn, wantedChecksum, loadedChecksum])

	return null
}

export default AppListLoader
